// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
		font-weight: 700;
	}

	dd {
		width: 60%;
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $medium;
	border: none;
	color: $dark;
	display: table;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1rem;
	padding: .6rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;
	width: 100%;

	&:hover, &:active, &:focus {
		background: $secondary;
		color: $light;

		i {
			color: $light;
		}
	}
	i {
		font-family: $icon-font;
		font-style: normal;
		color: $secondary;
		margin-left: .5rem;
		padding-right: .5rem;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}


.btn-small {
	background: $secondary;
	border: none;
	color: $light;
	display: table;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1rem;
	padding: .6rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;
	width: 40%;

	&:hover, &:active, &:focus {
		background: $medium;
		color: $dark;

		i {
			color: $dark;
		}
	}
	i {
		font-family: $icon-font;
		font-style: normal;
		color: $secondary;
		margin-left: .5rem;
		padding-right: .5rem;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);
	
	@include breakpoint(large) {
		padding-top:0;
	}
}

// HEADER
//////////////////////////////

.header {
	position: relative;
	width: 100%;
	/*border-top: 3.3rem solid $light;*/

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5f6f7+0,ffffff+40,ffffff+100 */
	background: #f5f6f7; /* Old browsers */
	background: -moz-linear-gradient(top, #f5f6f7 0%, #ffffff 40%, #ffffff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f5f6f7 0%,#ffffff 40%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f5f6f7 0%,#ffffff 40%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f6f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

	.header-inner {
		background-image: url(../images/header-small.jpg);
		background-position: -115px 0;
		background-size: 145%;
		background-repeat: no-repeat;
		height: 100%;
		padding: 2rem 0 0 0;
		position: relative;
		


		@include breakpoint(tiny) {
			background-image: url(../images/matrazten-experte-gesunder-schlaf.png);
		    background-position: -184px -79px;
		    background-size: 120%;
		}

		@include breakpoint(small) {
		    background-position: -103px -79px;
		    background-size: 100%;
		}

		@include breakpoint(medium) {
		    background-position: -104px -179px;
		    background-size: 89%;
		}

		@include breakpoint(large) {
		    background-position: -180px 0;
		    padding: 12rem 0 0 0;
		    background-size: contain;
		}

		.row {
			margin-bottom: 0;
		}

		.branding {
			max-width: 196px;
			margin-top: 1rem;
			position: relative;
			top: -0.5rem;
			z-index: 500;
			img {
				max-width: 196px;
			}

			@include breakpoint(medium) {
			    max-width: 140px;

			    img {
			    	max-width: 140px;
			    }
			}


			@include breakpoint(large) {
				position: absolute;
				top: -7.5rem;
				margin-left: 0;

				&.fixed {
					position: fixed;
					top: 2.6rem;
    				z-index: 300;
    				max-width: 150px;
				}
			}


			@include breakpoint(huge) {
			    margin-left: -3rem;
			    max-width: 196px;

			    img {
			    	max-width: 196px;
			    }

			    &.fixed {
			    	margin-left: -2rem;
    				img {
    					max-width: 150px;
    				}
			    }
			}
		}

		h1 {
			text-align: left;
    		font-size: 1.7rem;
    		line-height: 1.8rem;
			margin-bottom: 0;
			color: $secondary;
			text-align: right;

			@include breakpoint(tiny) {
			    font-size: 2rem;
				line-height: 2.2rem;
			}	

			@include breakpoint(huge) {
			    text-align: left;
			    font-size: 2.8rem;
			    line-height: 2.8rem;
			}

			&:after {
				display: none;
			}
		}

		h2 {
			color: $secondary;
			font-weight: 400;
			font-size: 1.2rem;
			text-align: right;
			

			@include breakpoint(tiny) {
			    margin-bottom: 5rem;
			}

			@include breakpoint(small) {
			    margin-bottom: 9rem;
			}

			@include breakpoint(large) {
			    margin-bottom: 5.1rem;
			}

			@include breakpoint(giant) {
			    margin-bottom: 3.9rem;
			}

			@include breakpoint(huge) {
			    text-align: left;
			    margin-bottom: 2.6rem;
			    font-size: 1.65rem;
			}
		}

		.btn.tel {
			background: #f5f6f7;
			text-decoration: none;
			margin-top: -3rem;
			margin-bottom: 1rem;
			
			@include breakpoint(medium) {
				margin-top: -6rem;
			}

			@include breakpoint(large) {
				margin-top: -2rem;
			}


			&:hover {
				background: $secondary;
			}
			@include breakpoint(large) {
			    width: auto;
			    float: right;
			    margin-right: -1rem;
			}

			@include breakpoint(huge) {
			    padding: .7rem 2.2rem .7rem 1rem;
			    font-size: 1.2rem;
			}
		}

		.start {
			padding-top: 2rem;
			text-align: right;
			@include breakpoint(huge) {
			    text-align:left;
			    font-size: 1rem;
			    padding-top: 5rem;
			    position: relative;
			    z-index: 25;
			}
		}

		.btn.tease {
			background: $secondary;
			color: $light;
			text-align:left;
			width: 100%;
			position: relative;
			font-size: 1.4rem;
			line-height: 1.3rem;
			margin-bottom: 1.3rem;
			padding: .9rem 2.5rem 0.8rem 1rem;
			z-index: 25;

			@include breakpoint(large) {
			    padding: 1.9rem 1.4rem 1.5rem 1.3rem;
			    font-size: 1.7rem;
			}

			i {
				color: $light;
				height: 100%;
				background: lighten($secondary,3%);
				position: absolute;
				right: 0;
				top:0;
				height: 100%;
				padding: 1rem 0.5rem;
				font-size: 0.8rem;

				@include breakpoint(large) {
				    padding: 2.5rem 1rem;
				}
			}

			span {
				font-size: .9rem;
				display: none;
				@include breakpoint(large) {
				    display: block;
				}
			}

			&:hover {
				background: lighten($secondary,3%);
				i {
					background: lighten($secondary,6%);
				}
			}
		}

		.btn.tease-orange {
			background: $alert;
			color: $light;
			text-align:left;
			width: 100%;
			position: relative;
			font-size: 1.4rem;
			line-height: 1.3rem;
			margin-bottom: 1.3rem;
			padding: 1rem 2.5rem 0rem 1rem;
			z-index: 25;

			@include breakpoint(large) {
			    padding: 1.9rem 3rem 1.5rem 1.3rem;
    			font-size: 1.1rem;
			}

			i {
				color: $light;
				height: 100%;
				background: lighten($secondary,3%);
				position: absolute;
				right: 0;
				top:0;
				height: 100%;
				padding: 1.8rem 0.5rem;
				font-size: 0.8rem;

				@include breakpoint(large) {
				    padding: 4.5rem 0.7rem;
				}
			}

			span {
				font-size: .9rem;
				display: none;
				@include breakpoint(large) {
				    display: block;
				}
			}

			&:hover {
				background: lighten($secondary,3%);
				i {
					background: lighten($secondary,6%);
				}
			}

			body:not(.index) & {
				width: 80%;
				margin-left: 1rem;

				@include breakpoint(medium) {margin-left: 18.66667%; width: 60%;}
				@include breakpoint(large) {width: 39%;}
			} 
		}

		.konfi {
			padding-top:.7rem;

			@include breakpoint(tiny) {
			    background: #f5f6f7;
			}

			@include breakpoint(huge) {
				padding-top:1rem;
			    margin-top: 5.8rem;
			}

			.no-size {
				float: left;
				margin-right: 1.2rem;
				width: 116px;
				display: none;

				@include breakpoint(large) {
				    width: auto;
				    display: block;
				    position: relative;
				    z-index: 25;
				}
			}

			.img-text {
				display: none;
				@include breakpoint(large) {
				    padding: 1.2rem 0 0 0;
				    display: block;
				    position: relative;
				    z-index: 25;
				}
			}
		}

		.matratze {
			@include breakpoint(huge) {
			    background: image-url('elax-matratze.png') left bottom no-repeat;
			    position: absolute;
			    width: 20%;
			    height: 100%;
			    display: block;
			    top: 0;
			    right: 0;
			    z-index: 20;
			}

			@include breakpoint(super) {
			    width: 23%;
			}
		}
	}

	body:not(.index) & {
		.header-inner {
			 background-image: url(../images/header-small.jpg);
		    background-size: cover;
		    display: flex;
		    margin-bottom: 3rem;
		    background-repeat: no-repeat;
		    height: 500px;
		    padding: 2rem 0 0 0;
		    position: relative;

			@include breakpoint(tiny) {
				background-image: image-url('header-medium.png');
			    background-position: -114px 8px;
			}

			@include breakpoint(medium) {
				 background-image: image-url('matrazten-experte-gesunder-schlaf.png');
				 height: 400px;
			     background-position: -177px -112px;
   				 background-size: 56%;

   		     }

			@include breakpoint(large) {
				padding: 0 0 0 0;
			    background-position: -163px 3px;
			    height: 600px;
			    background-size: contain;
			}

			.row {
				margin-bottom: 0;
				height: 100%;
			}
		}
	
		h1 {
		    font-size: 1.2rem;
			line-height: 2rem;
			margin-right: 33px;
			color: $dark;
			text-align: left;

			@include breakpoint(tiny) {
			    font-size: 1.2rem;
				line-height: 2rem;
			}	

			@include breakpoint(medium) {
			    font-size: 1.2rem;
				line-height: 2rem;
			}	

			@include breakpoint(large) {
			  
			    font-size: 2rem;
			    line-height: 2rem;
			}

			@include breakpoint(huge) {
         	    text-align: left;
			    font-size: 1.2rem;
			    line-height: 2rem;
			}


			&:after {
				display: none;
			}
		}

			h2 {
			color: $dark;
			margin-bottom: .9rem;
			margin-right: 33px;
			font-weight: 400;
			margin-top: .5rem;
			font-size: 1.2rem;
			 text-align: left;

			@include breakpoint(tiny) {
			    margin-bottom: 2rem;
			}

			@include breakpoint(large) {
			    margin-bottom: 1rem;
			}

			@include breakpoint(huge) {
			    text-align: left;
			    font-size: 1.1rem;
			}
		}

	}

}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	.background {
		padding-top: 4rem;
		padding-bottom: 0.1rem;
		width: 100%;
		background: $light;
		display: block;
	}

	.padding-top {
		padding-top: 4rem;
	}

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.teaser {
		background: image-url('bg-teaser.jpg') no-repeat;
		background-attachment: fixed;
		background-size: cover;
		padding: 6rem 0 2rem 0;
		margin-bottom: 5.5rem;
		a {
			padding:5rem 2rem;
			text-decoration: none;
			background: $light;
			display: block;
			margin-bottom: 1.1rem;
			text-align: center;
			transition: 300ms all ease-in-out;
			position: relative;
			overflow: hidden;
			height: 90%;
			font-weight: 300;

			&.shop {
				padding:2rem;
			}
			i {
				font-family: $icon-font;
				font-style: normal;
				font-size: 2rem;
				color: $secondary;
			}
			h2 {
				color: $secondary;
				font-weight: 700;
			}
			h2, p {
				margin-bottom: 0;
			}
			> div {
				position: absolute;
				top: 18rem;
				padding: 1.5rem;
				text-align: left;
				left: 0;
				transition: 300ms all ease-in-out;
				background: $secondary;
				color: $light;
				font-size: 95%;
				line-height: 1.4rem;
			}
			&:hover {
				> div {
					top: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.gesundheit {
		width: 100%;
		background: #f5f6f7;
		height: 40rem;
		/*background: #f7f8f9;
		background: -moz-linear-gradient(top, #f7f8f9 0%, #ffffff 48%, #ffffff 99%); 
		background: -webkit-linear-gradient(top, #f7f8f9 0%,#ffffff 48%,#ffffff 99%); 
		background: linear-gradient(to bottom, #f7f8f9 0%,#ffffff 48%,#ffffff 99%); 
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f8f9', endColorstr='#ffffff',GradientType=0 ); 
		position: relative;*/

		@include breakpoint(tiny) {
			height: 31rem;
		}

		@include breakpoint(small) {
			height: 27rem;
		}
		@include breakpoint(medium) {
			height: 25rem;
		}

		@include breakpoint(large) {
			height: 30rem;
		}

		h2 {
			font-size: 2rem;
    		line-height: 2.2rem;
    		margin-top: 6rem;
			color: $secondary;
			position: relative;



				@include breakpoint(tiny) {
			
					margin-top: 1rem;
			}

			@include breakpoint(medium) {
				&:before {
					position: absolute;
					width: 8rem;
					height: .06rem;
					background: $secondary;
					content: '';
					left: -8rem;
					bottom: 0;
				}
			}

			@include breakpoint(giant) {
				font-size: 2.6rem;
				line-height: 2.9rem;
				margin-top: 4rem;

			}
		}

		.ruecken, .schulter-nacken, .verspannungen, .schlafstoerungen {
			height: 44rem;

			@include breakpoint(tiny) {
			    height: 30rem;
			}

			@include breakpoint(small) {
			    height: 26rem;
			}

			@include breakpoint(medium) {
			    height: 25rem;
			}

			@include breakpoint(large) {
			    height: 30rem;
			}
			.row {
				padding: 4rem 0  9rem 0;
				margin-bottom: 0;
				height: 30rem;


				@include breakpoint(tiny) {
				    padding: 9rem 0;
				}

				@include breakpoint(medium) {
				    height: 26rem;
				    padding: 7.8rem 0;
				}

				@include breakpoint(large) {
				    background-size: contain;
				    padding: 10rem 0 5rem 0;
				    background: image-url('gesundheit-ruecken.png') no-repeat;

				    background-size: 50.5%;
					background-position: -111px;
					height: 30rem;

					.schulter-nacken & {
						background: image-url('gesundheit-nackenbeschwerden.png') no-repeat;
					}

				}

				@include breakpoint(giant) {
				    padding: 5rem 0;
				    background-position: 0 0;
				    background-size: 41.7%;
				}
			}
		}

		@include breakpoint(large) {
			.schulter-nacken {
				.row {
					background: image-url('gesundheit-nackenbeschwerden.png') no-repeat;
				}
			}

			.verspannungen {
				.row {
					background: image-url('gesundheit-verspannungen.png') no-repeat;
				}
			}

			.schlafstoerungen {
				background: image-url('gesundheit-schlafstoerungen.png') no-repeat;
				background-position: -72px bottom;
				background-size: 52.2% auto;
				.row {
					background: transparent;
			    }	
			} 
		}
	}
	
	img.ansprechpartner {
		margin-bottom: .6rem;
	}


	.erfahrungen {
		position: relative;
		width: 100%;
	}

}

// FOOTER
//////////////////////////////

.footer {
	background: $background;
	padding: 5.5rem 0 3.5rem 0;

	.row {
		margin-bottom: 2rem;

		p {
			margin-bottom: .8rem;
		}
	}

	@include breakpoint(large) {
	    padding: 5.5rem 0 0.6rem 0;
		.border-top { 
			border-top: .05rem solid $light;
			padding-top: 4rem;
		}
	}


	ul.unstyled-list {
		li {
			position: relative;
			padding-left: 1.5rem;
			i {
				font-family: $icon-font;
				font-style: normal;
				color: $secondary;
				position: absolute;
				left: 0;
			}
		}
	}
}

.youtube {
	width: 100%;
	height: 317px;
	border:0;
}

.headline {

			position: absolute;
			top: calc(50% + 3rem);
    		transform: translateY(-50%);

    		body:not(.index) & {
    			@include breakpoint(medium) {top: calc(50% + 0rem);}
    			@include breakpoint(large) {top: calc(50% + 5rem);}

    		}


}

.imag-position {

	margin-left: 285px;
}

		body:not(.index) {


   .news-container {
	

    position: initial;
    margin-top: 1rem;
    -webkit-transform: translateX(4%);
    transform: translateX(4%);
    border: 1px solid #f98900;



    @include breakpoint(tiny) {


    position: initial;
    margin-right: 0.8rem;
    float: left;
  
      }
	}

  .news-container p {
	
	color: #f98900;
    font-size: 17px;
    font-weight: 300;
    margin: 0 0 0 0;
    padding: 14px 16px 14px 16px;


    @include breakpoint(medium) {

	 font-size: 26px;
     }

	@include breakpoint(giant) {

	 font-size: 30px;
     }
	
	@include breakpoint(huge) {
     font-size: 33px;
    line-height: 1.8rem;
	 
	 }

	}
	
.btn-news {
	background: #fff;
    color: #2a2a2a;
    display: table;
    font-size: 1rem;
    font-weight: 300;
    margin-top: -20rem;
    margin-right: 5rem;
    line-height: 1.4rem;
    text-align: center;
    text-decoration: none;
    transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $secondary;
		color: $light;

		i {
			color: $light;
		}
	  }

@include breakpoint(tiny) {
	
	margin-top: -23.4rem;
    margin-right: -1rem;
    margin-left: 8.6rem;
	 }

@include breakpoint(small) {
	
	margin-top: -18rem;
	margin-left: 0.5rem;

	 }

	@include breakpoint(medium) {
	
	margin-top: -18rem;
	margin-left: 7rem;

	 } 	 	  	 	  	  

 @include breakpoint(large) {
	
	margin-top: -3rem;
	margin-left: 8.5rem;

	 } 	  


  @include breakpoint(giant) {
	
	margin-top: -28px;
	margin-left: 9.5rem

	 } 

 @include breakpoint(huge) {

	  margin-top: -11px;
	  margin-left: 4.5rem
     
     }

   } 

	

		 

		.header .header-inner .branding {

			max-width: 196px;
			margin-top: 1rem;
			position: relative;
			top: -0.5rem;
			z-index: 500;
			img {
				max-width: 196px;
			}

			@include breakpoint(medium) {
			    max-width: 140px;

			    img {
			    	max-width: 140px;
			    }
			}


			@include breakpoint(large) {
				position: absolute;
				top: 4rem;
				margin-left: 0;

				&.fixed {
					position: fixed;
					top: 3rem;
    				z-index: 300;
    				max-width: 150px;
				}
			}


			@include breakpoint(huge) {
			    margin-left: -3rem;
			    max-width: 196px;

			    img {
			    	max-width: 196px;
			    }

			    &.fixed {
			    	margin-left: -2rem;
    				img {
    					max-width: 150px;
    				}
			    }
			}
		}
}


.gr {

	width: 55%;
}

.size-1 {

	    margin-bottom: 30px;
}
.printorder{
	@include breakpoint(medium) {
	
	
	td{
		width: 50%;
	}
	tr{
		width: 50%;
	}
	 }
}
#printbutton{
		margin-bottom: 30px;
}

#ewpm_navi_files li {
        		float:left;
        		margin-right: 15px;
        	}
        	#ewpm_navi_files li:last-child {
        		margin-right: 0;
        	}        	


ul.styled-list li:before, main ul li:before {

	content: "\f105";
    font-family: "FontAwesome";
    color: #004d99;
    line-height: 1.5rem;
    margin-right: -0.5rem;
    position: absolute;
    left: 14px;
}



// Log-In 
//////////////////////////////

form.default {
    overflow: hidden;
    width: 100%;
}


form.default fieldset {

	border: none;
    margin: 0 0 1rem 0;
}

form.default label {
    clear: both;
    float: left;
    font-weight: 500;
    padding-right: 1rem;
    width: 29%;
}

form.default [type="text"], 
form.default [type="tel"], 
form.default [type="email"], 
form.default [type="file"], 
form.default input, 
form.default #newsletter-info, 
form.default textarea, 
form.default select {
    float: left;
    width: 71%;
}


form.default button, form.default [type="submit"] {
    float: right;
    width: 71%;
}

form.default [type="text"], 
form.default [type="tel"], 
form.default [type="email"], 
form.default [type="file"], 
form.default input, form.default #newsletter-info, 
form.default textarea, form.default select {
    background: #eef3f5;
    border: 1px rgba(125,28,143,0) solid;
    color: #2a2a2a;
    display: block;
    font-size: .88889rem;
    line-height: 1em;
    margin: 0 0 .55556rem 0;
    padding: .5rem 0.8rem .61111rem;
    position: relative;
    resize: none;
    transition: border .3s;
     }

form.default button, form.default [type="submit"] {
    background: #004d99;
    border: none;
    color:  #eef3f5;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 0 1.75rem;
    padding: .38889rem 15px .5rem;
    min-height: 0;
    transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
}



// Navigation geschÃ¼tze Seiten
//////////////////////////////


.boxing {

	margin-left: -1.3rem;
	
  li {
    width:100%;
    
    &:before {
      display: none;
    }

    a {
      background: #7e7e7e;
      padding: 10px;
      color: #FFF;
      text-decoration: none;
      display: block;
      text-align: center;


      &:hover {
                background: #eef3f5;
                color: $dark;

      }
    }
  }
}

#ewpm_navi_files li {
		top: -1.5rem;
 }


//////news//////////


.news-container {
	
	position: initial;
	float: right;
	margin-bottom: 4rem;
	background-color: $light;
	margin-top: 1rem;
	margin-right: 0.8rem;
    transform: translateX(4%);
    border: 1px solid #f98900;

	@include breakpoint(large) {
	margin-top: 1rem;
	margin-right: 0rem;
    top: 14rem;
    left: 16rem;
    position: initial;
  }

   @include breakpoint(giant) {
   	margin-top: 15px;	
    top: 14rem;
    left: 16rem;

     }
	}

  .news-container p {
	color: #f98900;
    line-height: 0.9rem;
    font-size: 15px;
    text-align: right;
    font-weight: 300;
    margin: 11px 0px 0 0;
    padding: 14px 16px 14px 16px;

    @include breakpoint(tiny) {
    font-size: 16px; 
    }

    @include breakpoint(small) {
    font-size: 16px; 
    }
	
	@include breakpoint(huge) {
    font-size: 18px;
    line-height: 1.3rem;
    text-align: right;
	 
	 	}
	}

	.h3-news {
	font-weight: bold;
    margin-top: 0.66rem;
    font-size: 0.9rem;
    padding-right: 1rem;
    margin-left: 0rem;
    line-height: 1.2rem;
    text-align: right;
    margin-bottom: -1rem;
    color: #f98900;

    @include breakpoint(tiny) {
	   font-size: 0.87rem;
	   margin-left: 0rem
     }

    @include breakpoint(small) {
	font-size: 1rem;
    margin-bottom: -1rem;
    margin-left: 0rem
     }

    @include breakpoint(large) {
	font-size: 0.87rem;
     }

	@include breakpoint(giant) {
	font-size: 1.05555rem;
     }

	@include breakpoint(huge) {	
	margin-top: 0.6rem;	
	font-size: 1.05555rem;
    margin-bottom: 0rem;
	}	
}

body:not(.index) { 
.news-container {
	
	display: none;

	@include breakpoint(tiny) {
	position: initial;
	display: block;
	float: left;
	margin-top: -4rem;
    margin-right: 2.8rem;
    transform: translateX(4%);
    border: 1px solid #f98900;
    left: 16rem;
    bottom: 0; 
  }

  @include breakpoint(small) {
  	margin-top: -8rem;
  }

    @include breakpoint(medium) {
  	margin-top: -21rem;
    margin-left: 7.3rem;
    top: 14rem;
  }

  @include breakpoint(large) {
	margin-left: 8.8rem;
    margin-top: -4rem;
  }

   @include breakpoint(giant) {
  	margin-top: -54px;
    margin-left: 10.5rem;
     }

    @include breakpoint(huge) {

    margin-top: -34px;	
    margin-left: 4.4rem; 
	}
}

  .news-container p {
	display: none;

    @include breakpoint(tiny) {
    color: #f98900;
    display: block;
    line-height: 0.9rem;
    text-align: left;
    font-weight: 300;
    margin: 0 0 0 0;
    padding: 14px 16px 14px 16px;
    font-size: 13px; 
    }

    @include breakpoint(small) {
    	font-size: 17px;
    }
	
	@include breakpoint(huge) {
    font-size: 18px;
    line-height: 1.3rem;
    text-align: left;
	 
	 	}
	}

	.h3-news {
	
	display: none;

    @include breakpoint(tiny) {
    font-weight: bold;
    display: block;
    margin-top: 0.66rem;
    padding-right: 1rem;
    margin-left: 1rem;
    text-align: left;
    margin-bottom: -1rem;
    color: #f98900;	 		
	 font-size: 0.77rem;
     }

    @include breakpoint(small) {
	 font-size: 0.9rem;
    margin-bottom: 0rem;
     }

    @include breakpoint(large) {
	font-size: 0.87rem;
     }

	@include breakpoint(giant) {
	font-size: 1.05555rem;
     }

	@include breakpoint(huge) {	
	margin-top: 0.6rem;	
	font-size: 1.05555rem;
    margin-bottom: 0rem;
		}	
	}
}	

.head1 {
	margin-top: 1rem;
	margin-bottom: 4rem;
}

.youtube {
	height: 600px;
    width: 100%;
	}