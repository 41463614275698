.formdefault {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $primary;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $primary;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $primary;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $primary;
    }

    fieldset {
        border:none;
        margin:0 0 3.5rem 0;
    }

    label, input, textarea, select, button, [type="submit"], a.btn {
        min-height: 3.5rem;
    }

    label {
        color: inherit;
        display: block;
        padding: 1rem 0 0.6rem;
        position:relative;

        small {
            color: $alert;
            display: block;
            line-height: 0.5rem;
            position:absolute;
        }

        &[for="Zustimmung"] {
            float:left;
            margin-bottom:.5rem;
            max-width:90%;
        }

        &.checkbox {
            width:100%;
        }

        &.error {
            -webkit-animation: error 1s;
            animation: error 1s;
            background: $alert;
            color: $light;
            font-size: 0.9rem;
            max-width:none;
            margin: 0 0 0.5rem 0;
            padding-left: rem($base-gap);
            padding-right: rem($base-gap);
            position: relative;
            text-align: center;
            width: 100%;

            @include breakpoint(tiny) {
                font-size: 1rem;
            }

            &:before {
                border-bottom: rem(10px) solid $alert;
                border-left: rem(7px) solid transparent;
                border-right: rem(7px) solid transparent;
                border-top: 0;
                content: "";
                height: 0;
                position: absolute;
                right: rem(10px);
                top: rem(-10px);
                width: 0;
            }
        }
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background: $background;
        border: 0;
        color: $dark;
        display: block;
        font-family: $main-font;
        font-size: 1rem;
        line-height: rem($base-line-height);
        margin: 0 0 0.5rem 0;
        padding: 0.8rem;
        position: relative;
        resize: none;
        transition: border .3s;
        width:100%;

        &:focus {
            background: darken($light, 2%);
            outline: none;
        }
    }

    textarea { /* fake line-height */
        min-height:7.5rem;
        padding-top:0.9rem;

        $inputgroup-count: 3;

        &[name="Nachricht"] {
            min-height:7.5rem * $inputgroup-count - 4rem;
        }
    }

    [type="checkbox"] {
        display: block;
        float: right;
        margin: 0 0.75rem 0.5rem 0;
        width: auto;
    }

    [type="radio"] {
        clear: none;
        float: left;
        width: 15px;
        margin: .4rem .8rem 0 0;
        min-height: auto;
    }

    button, [type="submit"], a.btn {
        background: $primary;
        border: none;
        color: $light;
        cursor: pointer;
        font-family: $main-font;
        font-size: 1rem;
        margin: 0 0 1.75rem;
        padding: 0.8rem $base-gap;
        width: 100%;

        &:hover, &:focus {
            background: $secondary;
        }
    }

    .errorContainer {
        position: relative;
    }
}
#recaptcha{
	display: block;
    clear: both;
    margin-bottom: 30px;
    float: right;
}
@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility:hidden !important;
}

#newsletter-info {
    @extend input;
    font-weight:bold;
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}



.checkbox {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  > input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

  .checkbox__icon {
    float:right;

    @extend %icon;
    display: inline-block;
    
    /* Default State */
    color: #999;
    @extend .icon--check-empty;
    
    /* Active State */
    input[type="checkbox"]:checked ~ & {
      color: $secondary;
      @extend .icon--check;
    }
  }

  /* IE6-8 Fallback */
  @media \0screen\,screen\9 {
    .checkbox__icon {
      display: none;
    }
    .checkbox > input[type="checkbox"] {
      position: static;
    }
  }

/****************************
 ****************************
 ****************************
 * Helpers
 */
%icon:before {
  font-family: "icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon--check:before {
    content: "\e601";
}
.icon--check-empty:before {
    content: "\e600";
}
.geschlecht{
    .medium-6{
        label{
            display: inline-block;

        }
        input{
            right: 115px;
        }
    }
}
@font-face {
  font-family: "icons";
  font-weight: normal;
  font-style: normal;
  src: url("data:application/x-font-woff;charset=utf-8;base64,d09GRk9UVE8AAAR4AAoAAAAABDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAPgAAAD4fZUAVE9TLzIAAAHsAAAAYAAAAGAIIvy3Y21hcAAAAkwAAABMAAAATBpVzFhnYXNwAAACmAAAAAgAAAAIAAAAEGhlYWQAAAKgAAAANgAAADYAeswzaGhlYQAAAtgAAAAkAAAAJAPiAedobXR4AAAC/AAAABgAAAAYBQAAAG1heHAAAAMUAAAABgAAAAYABlAAbmFtZQAAAxwAAAE5AAABOUQYtNZwb3N0AAAEWAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA6+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHi2v4lPh0BR0AAAB8Dx0AAACBER0AAAAJHQAAAO8SAAcBAQgPERMWGyBpY29tb29uaWNvbW9vbnUwdTF1MjB1RTYwMHVFNjAxAAACAYkABAAGAQEEBwoNL2X8lA78lA78lA77lA6L+HQVi/yU+JSLi/iU/JSLBd83Fffsi4v77Pvsi4v37AUOi/h0FYv8lPiUi4v33zc3i/s3++yLi/fs9zeL398F9wCFFftN+05JzUdI9xr7GveR95FHzwUO+JQU+JQViwwKAAMCAAGQAAUAAAFMAWYAAABHAUwBZgAAAPUAGQCEAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA5gEB4P/g/+AB4AAgAAAAAQAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAA4AAAACgAIAAIAAgABACDmAf/9//8AAAAAACDmAP/9//8AAf/jGgQAAwABAAAAAAAAAAAAAAABAAH//wAPAAEAAAAAAACkYCfgXw889QALAgAAAAAAz65FuwAAAADPrkW7AAD/4AIAAeAAAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAAgAAAQAAAAAAAAAAAAAAAAAAAAYAAAAAAAAAAAAAAAABAAAAAgAAAAIAAAAAAFAAAAYAAAAAAA4ArgABAAAAAAABAA4AAAABAAAAAAACAA4ARwABAAAAAAADAA4AJAABAAAAAAAEAA4AVQABAAAAAAAFABYADgABAAAAAAAGAAcAMgABAAAAAAAKACgAYwADAAEECQABAA4AAAADAAEECQACAA4ARwADAAEECQADAA4AJAADAAEECQAEAA4AVQADAAEECQAFABYADgADAAEECQAGAA4AOQADAAEECQAKACgAYwBpAGMAbwBtAG8AbwBuAFYAZQByAHMAaQBvAG4AIAAxAC4AMABpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuAFIAZQBnAHUAbABhAHIAaQBjAG8AbQBvAG8AbgBHAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4AAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==") format("woff");
}