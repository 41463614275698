// Main Navigation
//////////////////////////////

nav {
	@include breakpoint(large) {
	    padding-left: 18%;
	    top: 2.7rem;
	    position: relative;
	}

	@include breakpoint(giant) {
	    padding-left: 15%;
	    top: 3.5rem;
	}

	@include breakpoint(huge) {
	    padding-left: 17.4%;
	}
}

.navi-main {
	@include breakpoint(large) {
	    display:flex; 
	    justify-content:space-between;
	    width: 100%;
	}

	li {
		margin-bottom: .3rem;
		&:hover,&.active {
			a, span {
				text-decoration: underline;
			}
		}

		&.active {
			a {
				font-weight: 700;
				text-decoration: none;
			}
		}

		a, span {
			background: $secondary;
			color: $light;
			display: block;
    		padding: 0.5rem 0.8rem;
    		font-weight: 700;
    		transition: 300ms all ease-in-out;
    		text-decoration: none;

    		@include breakpoint(large) {
    		    color: $primary;
    		    font-weight: 300;
    		    font-size: 0.8rem;
    		    background: transparent;
    		    padding: 0;
    		}

    		@include breakpoint(giant) {
    		    font-size: 1rem;
    		}
		}

		&.hasSub {
			@include breakpoint(large) {
				position: relative;
				padding-right: .8rem;

				a.active {
					font-weight: 700;
				}
				&:after {
					font-family: $icon-font;
					color: #90d0f4;
					content:"\f107";
					position: absolute;
					right: 0;
					top: 0;
				}
			}
			&:hover ul {
				display: block;
			}
		}

		.sub {
			padding-bottom: .5rem;
			background: $secondary;
			@include breakpoint(large) {
			    display: none;
			    position: absolute;
			    background: $background;
			    padding: 1rem;
			    width: 18rem;
			}
			li {
				margin-bottom: 0;
				a {
					text-decoration: none;
					font-weight: 500;
					padding: 0.2rem 0.8rem;

					&:hover, &.active {
						text-decoration: underline;
					}

					@include breakpoint(large) {
					    color: $primary;
					    padding: .2rem 0;
					}
				}

				.sub {
					display: none;
				}
			}
		}		
	}
}

.navi-add {
	display: none;

	@include breakpoint(large) {
	    display: block;
	    float: left;
		margin: 0 .7rem 3rem 0;
		
		&.matratze {
			width: 17%;
		}

		&.matratzenexperten {
			width: 20%;
		}

		&.geld {
			width: 20%;
		}

		&.ausbildungszentrum {
			width: 18%;
		}

		&.informationen {
			width: 18%;
		}

	    li {
	    	a {
	    		text-decoration: none;
	    		color: $primary;
	    		font-weight: 300;
	    	}

	    	ul {
	    		li {
	    			a, span {
	    				position: relative;
	    				font-size: 95%;
	    				padding-left: .8rem;
	    				display: block;
	    				&:before {
	    					content:"\f105";
	    					font-family: $icon-font;
	    					position: absolute;
	    					left: 0;
	    				}

	    				&:hover, &.active {
	    					text-decoration: underline;
	    				}
	    			}

	    			ul {
	    				li {
	    					a {
	    						font-size: 90%;
	    						position: relative;
	    						padding-left: 1.5rem;
	    						&:before {
			    					content:"\f105";
			    					font-family: $icon-font;
			    					position: absolute;
			    					left: 0.8rem;
			    				}

	    					}
	    				}
	    			}
	    		}
	    	}
	    }
	}

	@include breakpoint(giant) {
	   	&.matratze {
			width: 13%;
		}

		&.matratzenexperten {
			width: 21%;
		}

		&.geld {
			width: 25%;
		}

		&.ausbildungszentrum {
			width: 17%;
		}

		&.informationen {
			width: 18%;
		}
	}
}

.navi-add2 {

	@include breakpoint(large) {
	    display:flex; 
		justify-content:space-between;
		width: 100%;
	}

	li {
		margin-bottom: .2rem;
		a {
			background: $light;
			color: $primary;
			padding: 0.5rem 0.8rem;
    		font-weight: 700;
    		display: block;
    		text-decoration: none;
    		transition: 300ms all ease-in-out;
		}

		&:hover, &.active {
			a {
				background: $secondary;
				color: $light;
			}
		}

		@include breakpoint(large) {

			a {
			    padding: 0;
			    background:transparent!important;
			    font-weight: 300;	
			}

			&:hover, &.active {
				a {
					background: transparent;
					text-decoration: underline;
					color: $primary;
				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	background:lighten($background, 2%);
	left:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:map-get($breakpoints, tiny) * .8;
	z-index: 895;
    bottom: 0;
    max-width:100%;

    span.daten {
    	padding: .7rem .4rem;
    	display: block;
    	position: relative;
    	padding-left: 2.4rem;
    	border-bottom: .1rem solid $light;

    	a {
    		margin-top: -0.3rem;
    		text-decoration: none;
    		font-weight: 300;

    		&.tel {
    			i {
    				top: 0.96rem;
    			}
    		}
    	}
    	
    	@include breakpoint(large) {
    	    border-right: .07rem solid #f5f6f7;
    		display: inline-block;
    		border-bottom: 0;
    		padding-left: 2.2rem;

    		a {
	    		&.tel {
	    			i {
	    				top: 0.8rem;
	    			}
	    		}
    		}

    		&:last-child {
    			margin-top: 0.2rem;
    			border-left: .07rem solid #f5f6f7;
    		}
    	}

	    i {
	    	font-family: $icon-font;
	    	color: $secondary;
	    	padding-right: .5rem;
	    	font-style: normal;
	    	position: absolute;
	    	left: 1rem;
	    }
    }

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(large) {
		background: transparent;
		overflow: visible;
		overflow-y: initial;
		bottom:auto;
		left:auto;
		position: fixed;
		top:auto;
		width: 100%;
		z-index: 100;

		.top {
			background: $light;
			width: 100%;
			height: 3.3rem;

			span {
				margin-top: 0.2rem;
				float:right;
			}
		}

		&.fixed {
			background: $background;
			height: 7.5rem;
			.top {
				height: 3.5rem;

				span.daten {
					padding-left: 2.2rem;
					padding-top: 14px;


		    		a {
			    		&.tel {
			    			i {
			    				top: 0.86rem;
			    			}
			    		}
		    		}
	    		
	    		}
		}

			nav {
				top: 1.7rem;
			}
		}
	}
}

.mobile-bar {
	align-items:center;
	background:$background;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);

		i {
			color: $secondary;
		}

		a {
			text-decoration: none;
		}

		&:last-child {
            justify-content:flex-end;
        }
	}

	@include breakpoint(large) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
		color: $secondary;
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}