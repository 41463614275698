body.matratze-konfigurator-matratze {
    .wizard,
    .tabcontrol
    {
        display: block;
        width: 100%;
        overflow: hidden;
    }

    .wizard ul,
    .tabcontrol ul
    {
        list-style: none !important;
        padding: 0;
        margin: 0;
    }

    .wizard ul > li,
    .tabcontrol ul > li
    {
        display: block;
        padding: 0;
    }

    .wizard > .steps .current-info,
    .tabcontrol > .steps .current-info
    {
        position: absolute;
        left: -999em;
    }

    .wizard > .content > .title,
    .tabcontrol > .content > .title
    {
        position: absolute;
        left: -999em;
    }



    /*
        Wizard
    */

    .wizard > .steps
    {
        position: relative;
        display: block;
        width: 100%;
    }

    .wizard.vertical > .steps
    {
        display: inline;
        float: left;
        width: 30%;
    }

    .wizard > .steps .number
    {
        font-size: 1.429em;
    }

    .wizard > .steps > ul > li
    {
        width: 100%;

        @include breakpoint(tiny) {
            width: 50%;
        }

        @include breakpoint(small) {
            width: 33%;
        }
        @include breakpoint(giant) {
            width: 16.5%;
            font-size: 15px;        
        }
    }

    .wizard > .steps > ul > li,
    .wizard > .actions > ul > li
    {
        float: left;
    }

    .wizard.vertical > .steps > ul > li
    {
        float: none;
        width: 100%;
    }

    .wizard > .steps a,
    .wizard > .steps a:hover,
    .wizard > .steps a:active
    {
        display: block;
        width: auto;
        margin: 0 0.5em 0.5em;
        padding: 1em 1em;
        text-decoration: none;

    }

    .wizard > .steps .disabled a,
    .wizard > .steps .disabled a:hover,
    .wizard > .steps .disabled a:active
    {
        background: #eee;
        color: #aaa;
        cursor: default;
    }

    .wizard > .steps .current a,
    .wizard > .steps .current a:hover,
    .wizard > .steps .current a:active
    {
        background: $secondary;
        color: #fff;
        cursor: default;
    }

    .wizard > .steps .done a,
    .wizard > .steps .done a:hover,
    .wizard > .steps .done a:active
    {
        background: #9dc8e2;
        color: #fff;
        transition: all 300ms;
    }

    .wizard > .steps .done a:hover,
    .wizard > .steps .done a:active
    {
        background: $light;
        color: $dark;
    }

    .wizard > .steps .error a,
    .wizard > .steps .error a:hover,
    .wizard > .steps .error a:active
    {
        background: #ff3111;
        color: #fff;
    }

    .wizard > .content
    {
        background: $medium;
        display: block;
        margin: 0.5em;
        height: rem(850px);
        overflow: hidden;
        position: relative;
        width: auto;
    }

    .wizard.vertical > .content
    {
        display: inline;
        float: left;
        margin: 0 2.5% 0.5em 2.5%;
        width: 65%;
    }

    .wizard > .content > .body
    {
        float: left;
        position: absolute;
        width: 100%;
        height: 95%;
        padding: 2.5%;
    }

    .wizard > .content > .body ul
    {
        list-style: disc !important;
    }

    .wizard > .content > .body ul > li
    {
        display: list-item;
    }

    .wizard > .content > .body > iframe
    {
        border: 0 none;
        width: 100%;
        height: 100%;
    }

    .wizard > .content > .body input, .wizard > .content > .body textarea
    {
        display: block;
        border: 1px solid darken($medium,10%);
        background: #f9f9f9;
    }

    .wizard > .content > .body input[type="checkbox"]
    {
        display: inline-block;
    }

    .wizard > .content > .body input.error
    {
        background: rgb(251, 227, 228);
        border: 1px solid #fbc2c4;
        color: #8a1f11;
    }

    .wizard > .content > .body label
    {
        display: inline-block;
        margin-bottom: 0.5em;

        &.checkbox {
            border-bottom: 1px solid darken($medium,10%);        

            &.last {
                border-bottom: 0;
            }
        }
    }

    .wizard > .content > .body label.error
    {
        color: #8a1f11;
        display: inline-block;
    }

    .wizard > .actions
    {
        position: relative;
        display: block;
        text-align: center;
        width: 100%;
        margin-top: 50px;
    }

    .wizard.vertical > .actions
    {
        display: inline;
        float: right;
        margin: 0 2.5%;
        width: 95%;
    }

    .wizard > .actions > ul
    {
        display: inline-block;
        text-align: right;
    }

    .wizard > .actions > ul > li
    {
        margin: 0 0.5em;
    }

    .wizard.vertical > .actions > ul > li
    {
        margin: 0 0 0 1em;
    }


    .tabcontrol > .steps
    {
        position: relative;
        display: block;
        width: 100%;
    }

    .tabcontrol > .steps > ul
    {
        position: relative;
        margin: 6px 0 0 0;
        top: 1px;
        z-index: 1;
    }

    .tabcontrol > .steps > ul > li
    {
        float: left;
        margin: 5px 2px 0 0;
        padding: 1px;

    }

    .tabcontrol > .steps > ul > li:hover
    {
        background: #edecec;
        border: 1px solid darken($medium,10%);
        padding: 0;
    }

    .tabcontrol > .steps > ul > li.current
    {
        background: #fff;
        border: 1px solid darken($medium,10%);
        border-bottom: 0 none;
        padding: 0 0 1px 0;
        margin-top: 0;
    }

    .tabcontrol > .steps > ul > li > a
    {
        color: #5f5f5f;
        display: inline-block;
        border: 0 none;
        margin: 0;
        padding: 10px 30px;
        text-decoration: none;
    }

    .tabcontrol > .steps > ul > li > a:hover
    {
        text-decoration: none;
    }

    .tabcontrol > .steps > ul > li.current > a
    {
        padding: 15px 30px 10px 30px;
    }

    .tabcontrol > .content
    {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 35em;
        overflow: hidden;
        border-top: 1px solid darken($medium,10%);
        padding-top: 20px;
    }

    .tabcontrol > .content > .body
    {
        float: left;
        position: absolute;
        width: 95%;
        height: 95%;
        padding: 2.5%;
    }

    .tabcontrol > .content > .body ul
    {
        list-style: disc !important;
    }

    .tabcontrol > .content > .body ul > li
    {
        display: list-item;
    }












    a.btn, .actions button {
        min-height: auto;
    }



    legend {
        font-weight: bold;
        text-decoration: underline;
        font-size: 20px;
        margin-bottom: rem(15px);
    }

    select {
        background: #f9f9f9;
        padding: 19px;
        border: 1px solid darken($medium,10%);
        font-weight: 300;
    }


    .actions button {
        background: $secondary;
        color: $light;

        &:hover, &:focus, &:active {
            background: $primary;
        }

        // &.knownButton {
        //     background: $primary;
        //     color: $light;

        //     &:hover, &:focus, &:active {
        //         background: $secondary;
        //     }
        // }
    }

    .auswertungContainer {
        display:none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;

        .overlayFull{
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            content: '';
            background: rgba(0,0,0,0.6);
            z-index: 9999;
        }
        .auswertung {
            background-color: $light;
            padding: rem(20px);
            border: solid 1px darken($medium,10%);
            z-index: 99999; 
            position: fixed;
            top: 0;
            left: 0;

            @include breakpoint(large) {
                left: 50%;
                transform: translatex(-50%);
                top: rem(200px);
                width: 100%;   
                max-width: $row-max-width;
            }
        }
        .zurbestellung {
            background: $secondary;
            color: $light;

            &:hover, &:focus {
                background: $light;
                color: $dark;
            }
        }
        .btn {
        }
    }

    .firstPart {
        label {
            width: 100%;

            @include breakpoint (tiny) {
                width: 20%;
                float: left;
            }
            @include breakpoint (medium) {
                width: 30%;
            }
        }

        input {
             width: 100%;


            @include breakpoint (tiny) {
                width: 80% !important;
                float: left
            }
             @include breakpoint (medium) {
                width: 70% !important;
            }
        }

        select {
             width: 100%;

            @include breakpoint (tiny) {
                width: 80% !important;
                float: left
            }
             @include breakpoint (medium) {
                width: 70% !important;
            }
        }
    }

    .secondPart {

        label {
            float: left;
            width: 80%;

            @include breakpoint (medium) {
                width: 90%;
            }
        }

        [type="checkbox"],[type="radio"] {
            position: relative;
            top: 25px;
            min-height: 0;
            float:right!important;
            width:auto!important;
        }
    }


    .firstPart_ORDER {
        label {
            width: 100%;

            @include breakpoint (tiny) {
                width: 50%;
                float: left;
            }

            @include breakpoint (medium) {
                width: 60%;
            }
        }

        input {
             width: 100%;


            @include breakpoint (tiny) {
                width: 50% !important;
                float: left
            }
             @include breakpoint (medium) {
                width: 40% !important;
            }

        }

        [type="checkbox"],[type="radio"] {
            position: relative;
            top: 25px;
            min-height: 0;
            float:right!important;
            width:auto!important;
        }

        select {
             width: 100%;

            @include breakpoint (tiny) {
                width: 50% !important;
                float: left
            }
             @include breakpoint (medium) {
                width: 40% !important;
            }
        }
    }

    .gesamtpreis {
        font-size: rem(24px);
        text-align:center;
        &.fixed {
            @include breakpoint(medium) {
                position: fixed;
                bottom: 0px;
                background: $light;
                color: $dark;
                z-index: 10;
                width: 100%;
                text-align: center;
                left: 0;
                padding: rem(40px) 0px;
            }
        }
    }
    .legalnotice {
        padding-top: rem(20px);
        font-size: rem(13px);
        text-align:center;
    }
    .landhinweis {
        font-weight: 400!important;
        line-height: 19px!important;    

        small {
            font-weight: 400!important;
            line-height: 19px!important;            
            color: $secondary;
        }
    }
}