 // * Usage:
 // * ------
 // * .selector {
 // *      width:100%;
 // *
 // *      @include breakpoint(medium) {
 // *          width:90%;
 // *      }
 // *
 // *      @include breakpoint(large) {
 // *          width:960px;
 // *      }
 // * }
 // *
 // * Breakpoint min-widths are defined in the $breakpoints map (look in _config.scss)
@mixin breakpoint($point) {
    @if map-has-key($breakpoints, $point) {
        @media (min-width: map-get($breakpoints, $point)) { @content; }
    }
    @else {
        @warn "The breakpoint #{$point} is not associated with a min-width.";
    }
}
